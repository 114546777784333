exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-trust-privacy-js": () => import("./../../../src/pages/trust/privacy.js" /* webpackChunkName: "component---src-pages-trust-privacy-js" */),
  "component---src-pages-trust-terms-js": () => import("./../../../src/pages/trust/terms.js" /* webpackChunkName: "component---src-pages-trust-terms-js" */),
  "component---src-pages-why-index-js": () => import("./../../../src/pages/why/index.js" /* webpackChunkName: "component---src-pages-why-index-js" */),
  "component---src-pages-why-stai-vs-video-js": () => import("./../../../src/pages/why/stai-vs-video.js" /* webpackChunkName: "component---src-pages-why-stai-vs-video-js" */)
}

